import React from "react";
import { Box, Image, keyframes, Text } from "@chakra-ui/react";
import { Parallax } from "react-parallax";

// Image imports
import facebook from "../../../assets/img/facebook.jpg";
import line from "../../../assets/img/line.webp";
import tiktok from "../../../assets/img/tiktok.jpg";
import shopee from "../../../assets/img/shopee.png";
import central from "../../../assets/img/central.png";
import instagram from "../../../assets/img/instagram.png";
import kids from "../../../assets/img/kids.png";
import shein from "../../../assets/img/shein.png";
import sports from "../../../assets/img/sports.png";
import tools from "../../../assets/img/tools.png";
import toys from "../../../assets/img/toys.png";
import isumbuyLogo from "../../../assets/img/isumbuy_full.png";

// Right-to-left animation
const moveAnimation = keyframes`
  0% { transform: translateX(100vw); }
  100% { transform: translateX(-100vw); }
`;

// List of brand images
const brands = [
  { src: facebook, alt: "Facebook" },
  { src: line, alt: "Line" },
  { src: tiktok, alt: "TikTok" },
  { src: shopee, alt: "Shopee" },
  { src: central, alt: "Central" },
  { src: instagram, alt: "Instagram" },
  { src: kids, alt: "Kids" },
  { src: shein, alt: "Shein" },
  { src: sports, alt: "Sports" },
  { src: tools, alt: "Tools" },
  { src: toys, alt: "Toys" },
];

const LandingPage = () => {
  return (
    <Box
      position="relative"
      overflow="hidden"
      minHeight="100vh"
      bgGradient="linear(to-r, teal.400, blue.500, purple.600)"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      py={20}
      px={6}
    >
      {/* Parallax Background */}
      {/* <Parallax
        // bgImage={isumbuyLogo}
        strength={300}
        bgImageAlt="iSumBuy"
        // style={{ height: "300px", width: "100%" }}
      >
        <Box height="300px" />
      </Parallax> */}

      {/* Centered Logo */}
      <Box mb={10}>
        <Image
          src={isumbuyLogo}
          alt="iSumBuy"
          boxSize="250px"
          objectFit="contain"
        />
      </Box>

      {/* Text Below the Logo */}
      <Text fontSize="4xl" fontWeight="bold" color="white" mb={12}>
        iSumBuy ที่นี่ที่เดียว จบครบทุกสิ่งที่คุณอยากได้ <br />
        iSumBuy ຢູ່ທີ່ນີ້ເທົ່ານັ້ນ ສໍາເລັດດ້ວຍທຸກຢ່າງທີ່ທ່ານຕ້ອງການ.
      </Text>

      {/* Brand Images Moving from Right to Left */}
      <Box
        display="flex"
        justifyContent="space-around"
        animation={`${moveAnimation} 20s linear infinite`}
        whiteSpace="nowrap"
      >
        {brands.map((brand, index) => (
          <Box key={index} p={4} display="inline-block">
            <Image
              src={brand.src}
              alt={brand.alt}
              boxSize="150px"
              border="2px solid white"
              borderRadius="15px"
              objectFit="cover"
              transition="transform 0.3s ease-in-out"
              _hover={{
                transform: "scale(1.1)",
                filter: "brightness(110%)",
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LandingPage;
